<template>
  <div class="mb-20">
    <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
      <div class="flex flex-wrap items-center col-span-1">
        <h2 class="mr-3 text-2xl font-bold">Manage Notifications</h2>
      </div>
      <search-form
        v-model="searchQuery"
        placeholder="Search for title, message, etc."
        @submit="(event) => $refs.table.loadAjaxData()"
      />
    </div>
    <button
      class="btn btn-sm btn-blue mb-3"
      @click="$refs.scheduleNotificationModal.open()"
    >
      Schedule Notification
    </button>
    <button
      class="btn btn-sm ml-3 btn-yellow mb-3"
      @click="$refs.topicBasedNotificationModal.open()"
    >
      Topic-Based User Notification
    </button>

    <div class="border border-blue-200 border-solid">
      <div class="flex">
        <div class="duration-300 w-full">
          <datatable
            :url="url"
            :ajax="true"
            :ajaxPagination="true"
            :index="true"
            :reverse-index="true"
            :data="notifications.data"
            dropdown="actions"
            :pageDetails="true"
            :actions="actions"
            :columns="columns"
            :filters="filters"
            :query="searchQuery"
            :loading="notifications.loading"
            ref="table"
          />
        </div>
      </div>
    </div>

    <modal
      class-name="flex flex-col w-full sm:max-w-500px max-h-full min-h-400px"
      ref="scheduleNotificationModal"
    >
      <h3 class="font-bold mb-5 text-xl">Schedule Notification</h3>
      <form
        @submit.prevent="scheduleNotification"
        class="flex flex-col flex-grow"
      >
        <form-group
          v-model="notificationForm.data.date.value"
          :form="notificationForm"
          name="date"
          type="datetime-local"
        >
          Date
        </form-group>
        <form-group
          v-model="notificationForm.data.category_id.value"
          :form="notificationForm"
          name="category_id"
          type="select"
          :options="categories"
        >
          Select a category
        </form-group>
        <form-group
          v-model="notificationForm.data.title.value"
          :form="notificationForm"
          name="title"
          type="text"
        >
          Title
        </form-group>
        <form-group
          v-model="notificationForm.data.message.value"
          :form="notificationForm"
          name="message"
          type="textarea"
        >
          Message
        </form-group>

        <form-group
          v-model="notificationForm.data.cta.value"
          :form="notificationForm"
          name="title"
          type="text"
        >
          CTA Button Text
        </form-group>

        <div class="flex items-center gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray btn-md"
            @click.prevent="$refs.scheduleNotificationModal.close()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="notificationForm.loading"
          >
            <sm-loader-white v-if="notificationForm.loading" />
            <span v-else>Schedule Notification</span>
          </button>
        </div>
      </form>
    </modal>
    <modal
      class-name="flex flex-col w-full sm:max-w-500px max-h-full min-h-400px"
      ref="topicBasedNotificationModal"
    >
      <h3 class="font-bold mb-5 text-xl">Send Notification to A Topic</h3>
      <form
        @submit.prevent="sendTopicNotification"
        class="flex flex-col flex-grow"
      >
        <form-group
          v-model="notificationForm.data.topic.value"
          :form="notificationForm"
          name="topic"
          type="text"
        >
          Topic Name
        </form-group>

        <form-group
          v-model="notificationForm.data.title.value"
          :form="notificationForm"
          name="title"
          type="text"
        >
          Title
        </form-group>
        <form-group
          v-model="notificationForm.data.message.value"
          :form="notificationForm"
          name="message"
          type="textarea"
        >
          Message
        </form-group>

        <div class="flex items-center gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray btn-md"
            @click.prevent="$refs.topicBasedNotificationModal.close()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="notificationForm.loading"
          >
            <sm-loader-white v-if="notificationForm.loading" />
            <span v-else>Send Notification</span>
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedUsers: [],
      categories: [],
      url: `${this.$baseurl}/push-notifications`,
      columns: [
        {
          name: 'title',
          th: 'Title',
        },
        {
          name: 'category.name',
          th: 'Category',
          render: (data) => data?.category?.name || 'N/A',
        },
        {
          name: 'message',
          th: 'Message',
          render: (data) =>
            data?.message.length > 30
              ? data?.message.substr(0, 30) + '...'
              : data.message,
        },
        {
          name: 'cta',
          th: 'CTA',
        },
        {
          name: 'created_at',
          th: 'Date Created',
          render: (date, created_at) =>
            created_at
              ? this.$options.filters.dateFormat(created_at, 'D, dd M Y h:ia')
              : 'N/A',
        },
        {
          name: 'scheduled_date',
          th: 'Scheduled Date',
          render: (date, scheduled_at) =>
            scheduled_at
              ? this.$options.filters.dateFormat(scheduled_at, 'D, dd M Y h:ia')
              : 'N/A',
        },
      ],
      filters: [
        { title: 'all', name: 'all' },
        { title: 'Scheduled & Not Sent', name: 'scheduled' },
        { title: 'Scheduled & Sent', name: 'scheduled-sent' },
      ],
      actions: [
        {
          text: 'Delete',
          class: 'btn btn-red btn-sm',
          action: this.deleteNotification,
        },
      ],
      // notifications: this.$options.resource([]),
      notificationForm: this.$options.basicForm([
        { name: 'title', rules: 'required' },
        { name: 'message', rules: 'required' },
        { name: 'cta', rules: 'nullable' },
        { name: 'date', rules: 'nullable' },
        { name: 'topic', rules: 'nullable' },
        { name: 'category_id', rules: 'nullable' },
      ]),
      loading: null,
      searchQuery: '',
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      this.loading = true;
      await this.sendRequest('admin.pushNotifications.getCategories', {
        success: (response) => {
          this.categories = response.data.datatable.data.map((category) => ({
            title: category.name,
            value: category.id,
          }));
        },
        error: (error) => {
          console.log(error, 'error fetching categories');
        },
      });
    },

    async sendNotification(url, successTitle, successBody) {
      if (!this.validateForm(this.notificationForm)) return;

      this.notificationForm.loading = true;
      this.notificationForm.error = null;
      const formData = { ...this.getFormData(this.notificationForm) };
      const data = Object.fromEntries(
        Object.entries(formData).filter(([, value]) => {
          return value !== null && value !== '';
        })
      );

      await this.sendRequest(
        'admin.pushNotifications.scheduleOrSendTopicBasedNotification',
        url,
        {
          data: data,
          success: () => {
            this.$success({
              title: successTitle,
              body: successBody,
              button: 'Okay',
            });
            this.notificationForm = this.resetForm(this.notificationForm);
            this.$emit('success');
            this.reloadTable();
          },
          error: (error) => {
            this.notificationForm.error = error;
            this.mapFormErrors(
              this.notificationForm,
              error.response?.data?.errors
            );
          },
        }
      );
      this.$refs.scheduleNotificationModal.close();
      this.$refs.topicBasedNotificationModal.close();
    },

    async scheduleNotification() {
      const successBody = `Push notification scheduled successfully for ${this.notificationForm.data.date.value}`;
      await this.sendNotification(
        'push-notifications/schedule',
        'Notification Scheduled',
        successBody
      );
    },

    async sendTopicNotification() {
      const successBody = `Push notification sent successfully for ${this.notificationForm.data.topic.value}`;
      await this.sendNotification(
        'push-notifications/send/topic',
        'Topic Based Notification Sent',
        successBody
      );
    },

    async deleteNotification(notification) {
      console.log(notification);
      await this.sendRequest(
        'admin.pushNotifications.deleteNotification',
        notification.id,
        {
          success: () => {
            this.$success({
              title: 'Operation Successful',
              body: 'Push notification has been deleted',
              button: 'Okay',
            });
            this.reloadTable();
          },
          error: (error) => {
            console.log(error);
          },
        }
      );
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
  },
};
</script>
